.imgBanner {
    width: 100%;
    transition: .1s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.banner {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 5;
    box-shadow: 0 .15rem .6rem black;
}

.cvText {
    text-decoration: none;
    cursor: pointer;
    font-family: $nameFont;
    font-size: 4rem;
    color: $hoverLinkColor;
    border-radius: 50%;
    border: .25rem solid $hoverLinkColor;
    padding: 2rem 3rem;
    transition: .3s cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
        background-color: $hoverLinkColor;
        color: $backColor;
    }
}
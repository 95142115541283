

.videoContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 6rem;
}

.vimeos {
    padding: .5;
    margin: 1rem;
    background-color: black;

}


@media (max-width: 750px) {
    .vimeos {
        margin-bottom: 2rem;
    }
}
.formBox {
    display: flex;
    flex-direction: column;
    padding: 4rem 2rem;
    box-shadow: 0 0 .5rem $hoverLinkColor;
    border-radius: .3rem;
    background-color: $hoverLinkColor;
    width: 20rem;

    label {
        color: white;
        font-family: $nameFont;
        font-size: 1.5rem;
    }

    .contactInput {
        border: 0;
        background-color: $backColor;
        padding: .5rem;
        border-radius: .15rem;
        margin-bottom: .8rem;
        font-family: $dataText;
        font-size: .8rem;

        &:hover {
          background-color: white;
        } 
    }

    textarea {
        height: 5rem;
    }

    button {
        padding: .5rem;
        border: 0;
        border-radius: .15rem;
        background-color: $backColor;
        box-shadow: .15rem .15rem .2rem $shadowColor;
        cursor: pointer;
        color: $bgColor;
        margin-top: .5rem;
        
        transition: .3s cubic-bezier(0.075, 0.82, 0.165, 1);

        &:hover {
            background-color: white;
        }

        &:active {
            background-color: $shadowColor;
            color: $backColor;
            box-shadow: inset .15rem .15rem .2rem $bgColor;
        }
    }
}
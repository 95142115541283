.shadowbanner {
    position: fixed;
    top: 0;
    width: 100%;
    opacity: .85;
    z-index: 3;
    background-color: $bgColor;
    box-shadow: 0 .2rem .6rem black;
}

@media (max-width: 1599px) {
    .shadowbanner {
        height: 4.5rem;
    }
}

@media (min-width: 1600px) {
    .shadowbanner {
        height: 6.5rem;
    }
}
.bannerBook {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: top;
    height: 14rem;
    width: 100%;
    transition: .3s cubic-bezier(0.075, 0.82, 0.165, 1);
    box-shadow: 0 .3rem .5rem 0 gray;
}

// SECTION
// .book {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: flex-start;
// }

// PHOTOS
.photosBook {
    width: auto;
    height: 15rem;
    margin: .6rem;
    box-shadow: .15rem .15rem .5rem gray;
    border-radius: .2rem;
    transition: .3s cubic-bezier(0.075, 0.82, 0.165, 1);
    filter: brightness(.85);
    cursor: pointer;
    &:hover {
        filter: brightness(1.1);
    }

}

// PHOTOS CONTAINER
.picContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 60%;
    margin: 4rem 0 8rem;    
}

.noPic {
    display: none;
    position: relative;
}

// MODAL -- CONTAINER OF PHOTOS 
.bigPicContainer {
    position: fixed;
    display: flex;
    top: 0;
    justify-content: center;
    align-items: center;
    background-color: black;
    z-index: 100;
    width: 100vw;
    height: 100vh;
}

// PHOTO ON MODAL
.bigPic {
    height: 100%;    
}

// BOTONES GALERIA
.picActions {
    font-size: 1.5rem;
    position: absolute;
    color: white;
    cursor: pointer;
    border-radius: .2rem;
    &:hover {
        background-color: rgba(128, 128, 128, 0.2);
    }
    z-index: 999999999;
}

.pointer {
    padding: 4rem 1.5rem;
}

.prev {
    left: 2vw;
}

.next {
    right: 2vw;
}

.close {
    right: 1.5vw;
    top: .5vw;
    padding: 1rem 1.5rem;
    font-size: 2.2rem;
}

// MEDIAS

@media (max-width: 617px) {
    .picContainer  {
        width: 95%;
        margin: 2rem 0 4rem;
    }
    .bannerBook {
        height: 5rem;
    }
    .photosBook {
        height: 7rem;
        margin: .3rem;
    }
    .photosBook {
        width: 90%;
        height: auto;
    }
}

@media (max-width: 1535px) {
    .picContainer  {
        width: 80%;
    }
}
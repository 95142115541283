.nombre {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    font: 400 normal 3.9rem $nameFont;
    color: $backColor;
    cursor: pointer;
    transition: .3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hoverLinks:hover {
    color: $hoverLinkColor;
    text-shadow: .05rem .05rem .1rem black;
    
    .navLinkText{
        color: $hoverLinkColor;
        transition: .3s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
}
#menu{
    display: none;
}

.navContainer {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 100;
}

#navbar{
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    width: 80%;
    justify-content: space-between;
}

.rightNav {
    display: flex;
    flex-direction: row;
    align-items: center;
}


.iconSize {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 1.8rem;
    width: 1.8rem;
    border-radius: 50%;
    margin: -.55rem 0 0 1.5rem;
    box-shadow: inset .15rem .15rem .15rem 0 rgba(0, 0, 0, 0.507);
    cursor: pointer;
    &:hover {
        box-shadow: .25rem .25rem .5rem 0 black;
    }
    &:active {
        filter: brightness(.8);
        box-shadow: inset .15rem .15rem .15rem 0 rgba(0, 0, 0, 0.507);
    }
}

.es {
    background-image: url("../../assets/icos/es.gif");
}

.en {
    background-image: url("../../assets/icos/en.gif");
}


.linksContainer{
    all: unset;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: .25rem 0;
    margin-top: -.4rem;
    border-top: .1rem solid $backColor;
    border-bottom: .1rem solid $backColor;
    

}

#desplegable{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

    &:hover {
        #menu {
            display: none;
        }
    }
}
.liLinks{
    all: unset;
    margin-left: .5rem;
}

.navLinkText{
    font-size: 1.2rem;
    font-weight: 300;
    text-decoration: none;
    font-family: $linkFont;
    cursor: pointer;
    color: $backColor;
    transition: .3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

@media (max-width:1600px)
{
    .nombre{
        font-size: 2.5rem;
    }

    .linksContainer{
        .navLinkText{
            font-size: 1rem;
        }
    }
}

@media (max-width: 1300px) {
    #navbar{
        flex-direction: row;
        align-items: flex-start;
        width: 90%;
    }

    .rightNav {
        align-items: flex-start;
        margin-top: .2rem;

        .iconSize {
            margin: 0 0 0 1.5rem;
        }
    }

    .linksContainer{
        align-items: center;
        flex-direction: column;
        background-color: $hoverLinkColor;
        position: relative;
        top: 1rem;
        width: 10rem;
        display: none;

        border-top: none;

        .navLinkText{
            font-size: 1rem;
        }

        .liLinks{
            box-sizing: border-box;
            width: 100%;
            padding: .2em 1em;
            margin: 0;
            text-align: right;

            &:hover{
                transition: .3s cubic-bezier(0.075, 0.82, 0.165, 1);
                background-color: black;
            }
        }
    }

    .nombre {
        font-size: 2rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-decoration: none;
        color: white;
        cursor: pointer;
        .bottomName {
            margin: 0 0 0 .5rem;
        }
    }

    #menu{
        display: flex;
        width: 1.45rem;
        height: 1.45rem;
        margin: .25rem 0 0 0;
        cursor: pointer;
    }

    #desplegable:hover .linksContainer{
        display: flex;
    }
}



@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Oswald:wght@200;300;400&family=Playfair+Display:ital@0;1&family=Source+Sans+Pro:ital,wght@0,200;0,400;1,200;1,400&display=swap');

$linkFont: 'Oswald', sans-serif;
$nameFont: 'Bebas Neue', cursive;
$textUrgent: 'Playfair Display', serif;
$dataText: 'Source Sans Pro', sans-serif;

$backColor: rgb(255, 251, 245);
$hoverLinkColor: rgb(204, 159, 91);
$shadowColor: rgb(160, 110, 34);
$textTitleColor: rgba(0, 0, 0, 0.815);
$bgColor: rgb(36, 36, 36);
.globalContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $backColor;
}

.globalSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 4rem 0 6rem;
}

.sectionTitle {
    all: unset;
    text-align: center;
    font-family: $nameFont;
    font-size: 3rem;
    // color: $titleSectionColor;
    color: $backColor;
    text-shadow: .15rem .15rem .35rem $shadowColor;
    width: 50%;
    border-bottom: .25rem double $hoverLinkColor;
    border-top: .25rem double $hoverLinkColor;
    padding: 2rem 0;
    margin: 4rem 0 4rem;
}

.textUrgent {
    text-align: center;
    font-family: $textUrgent;
    font-style: italic;
    font-size: 2rem;
    font-weight: 600;
    color: $hoverLinkColor;
}

.homeIntro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 6rem;
    
}

.dataContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items:flex-start;
    padding: 4rem 0;
    width: 100%;
    background-color: $bgColor;
    
    .paragraf {
        color: $backColor;
    }

    .dataTitle {
        all: unset;
        font-size: 1.3rem;
        font-weight: 600;
        font-family: $dataText;
        color: $backColor;
    }
    .dataImage {
        width: 15rem;
        height: auto;
        margin: 0 4rem 4rem;
        border-radius: .3rem;
        box-shadow: .15rem .15rem .5rem black;
    }

    .briefP {
        width: 40%;
    }
}




.linkText {
    text-decoration: none;
    color: $hoverLinkColor;
}

.paragraf {
    color: $textTitleColor;
    font-size: 1rem;
    font-weight: 400;
    font-family:  $dataText;
}

.portadaHome {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: 5;
}

@media (max-width: 900px) {
    .portadaHome {
        height: 20rem;
        align-items: flex-start;
    }
    .sectionTitle {
        font-size: 3rem;
    }
    .textUrgent {
        font-size: 1.5rem;
        width: 50%;
    }
    .dataContainer{
        .briefP {
        width: 80%;
        }
        .dataImage {
            width: 90%;
            margin: 0 0 2rem 0;
        }
    }
}
.playsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 80%;
    padding: 4rem 0;
}

.playsBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 18rem;
    padding: 1rem .5rem 2rem;
}

.playsBoxText {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem 0 2rem;
}

.playsTitle {
    display: flex;
    align-items: flex-start;
    height: 5rem;
}

@media (max-width: 700px) {
    .playsTitle {

        height: 2rem;
    }
}